/* Base styles for the container */
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-top: 0%;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Left section (welcome message) */
.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f0f4f8;
  color: #333;
  text-align: center;
}

/* Right section (login form) */
.right-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 75%;
}

/* Form styling */
.form {
  width: 100%;
  max-width: 300px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #212121;
}

.input {
  width: 95%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.button {
  width: 95%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 2px solid #fea697;
  background-color: inherit;
  font-weight: bold;
  cursor: pointer;
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 0;
  }
  .left-section,
  .right-section {
    padding: 5%;
    justify-content: flex-start;
  }
}
